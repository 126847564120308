<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0 pt-5">
			<h3 class="card-title align-items-start flex-column">
				<span class="card-label font-weight-bolder text-dark">{{ $t('VIEWS.Survey.All.title') }}</span>
			</h3>
			<div class="card-toolbar">
				<router-link :to="{ name: 'survey-create' }" v-slot="{ href, navigate, isActive, isExactActive }">
					<a :href="href" @click="navigate" class="btn btn-icon btn-light btn-sm mx-1" :class="!hasPermission('action.survey.add') ? 'disabled' : ''">
						<i class="fas fa-plus text-primary"></i>
					</a>
				</router-link>
			</div>
		</div>
		<div class="card-body py-3">
			<ContentLoading size="1.5" v-if="pageProcesses.isLoading"></ContentLoading>
			<div class="col-12 prinor-table" v-if="!pageProcesses.isLoading">
				<b-table ref="surveysTable" :data="surveys" :paginated="true" :per-page="15" default-sort="created_at" :default-sort-direction="'desc'" :sort-icon="'caret-up'">
					<b-table-column field="name" :label="$t('GENERAL.Forms.name')" v-slot="props" :sortable="true" :searchable="true" cell-class="text-valign">
						<template>
							<span class="truncate">{{ props.row.name }}</span>
						</template>
					</b-table-column>
					<b-table-column field="theme" :label="$t('VIEWS.Survey.All.theme')" v-slot="props" :sortable="false" :searchable="false" cell-class="text-valign">
						<template>
							<b-form-select v-model="props.row.theme_id" :options="themes" class="mb-3" value-field="id" text-field="name" @change="onSaveTheme($event, props.row.id)"></b-form-select>
						</template>
					</b-table-column>
					<b-table-column field="created_at" :label="$t('VIEWS.Survey.All.createdAt')" v-slot="props" :sortable="true" :searchable="false" cell-class="text-valign">
						<template>
							<span class="truncate">{{ new Date(props.row.created_at).toLocaleDateString($t('GENERAL.General.localeLong')) }}</span>
						</template>
					</b-table-column>
					<b-table-column field="started_at" :label="$t('VIEWS.Dashboard.Active.startdate')" v-slot="props" :sortable="true" :searchable="false" cell-class="text-valign">
						<template>
							<span class="truncate">{{ props.row.started_at !== null ? new Date(props.row.started_at).toLocaleDateString($t('GENERAL.General.localeLong')) : '' }}</span>
						</template>
					</b-table-column>
					<b-table-column field="completed_at" :label="$t('VIEWS.Dashboard.Completed.enddate')" v-slot="props" :sortable="true" :searchable="false" cell-class="text-valign">
						<template>
							<span class="truncate">{{ props.row.completed_at !== null ? new Date(props.row.completed_at).toLocaleDateString($t('GENERAL.General.localeLong')) : '' }}</span>
						</template>
					</b-table-column>
					<b-table-column field="id" :label="$t('GENERAL.General.actions')" v-slot="props" cell-class="action-td" header-class="action-td">
						<template>
							<a href="" @click.prevent="showSettings(props.row.id)" class="btn btn-icon btn-light btn-sm m-1" :class="!hasPermission('action.survey.settings') ? 'disabled' : ''" :title="$t('VIEWS.Survey.Settings.title')">
								<i class="fas fa-cog text-primary"></i>
							</a>
							<a href="" @click.prevent="showCopy(props.row.id, props.row.name)" class="btn btn-icon btn-light btn-sm mx-1" :class="!hasPermission('action.survey.copy') ? 'disabled' : ''" :title="$t('VIEWS.Survey.All.copyTooltip')">
								<i class="far fa-copy text-primary"></i>
							</a>
							<router-link :to="{ name: 'report', params: { id: props.row.id } }" v-slot="{ href, navigate, isActive, isExactActive }">
								<a :href="href" @click="navigate" class="btn btn-icon btn-light btn-sm m-1" :class="(props.row.started_at === null || !hasPermission('action.survey.report')) ? 'disabled' : ''" :title="$t('VIEWS.Dashboard.Active.reportTitle')">
									<i class="fas fa-file-medical-alt text-primary"></i>
								</a>
							</router-link>
							<router-link :to="{ name: 'survey-design', params: { id: props.row.theme_id } }" v-slot="{ href, navigate, isActive, isExactActive }">
								<a :href="href" @click="navigate" class="btn btn-icon btn-light btn-sm m-1" :class="!hasPermission('action.survey.theme') || checkThemePermission(props.row.theme_id) ? 'disabled' : ''" :title="$t('VIEWS.Survey.Theme.title')">
									<i class="fas fa-magic text-primary"></i>
								</a>
							</router-link>
							<router-link :to="{ name: 'survey-edit', params: { id: props.row.id } }" v-slot="{ href, navigate, isActive, isExactActive }">
								<a :href="href" @click="navigate" class="btn btn-icon btn-light btn-sm m-1" :class="!hasPermission('action.survey.edit') ? 'disabled' : ''" :title="$t('MENU.Survey.editSurvey')">
									<i class="fas fa-edit text-primary"></i>
								</a>
							</router-link>
							<a href="" @click.prevent="onDelete(props.row.id, props.row.name)" class="btn btn-icon btn-light btn-sm m-1" :class="!hasPermission('action.survey.delete') ? 'disabled' : ''" :title="$t('VIEWS.Survey.All.delete')">
								<i class="far fa-trash-alt text-danger"></i>
							</a>
						</template>
					</b-table-column>
				</b-table>
			</div>
		</div>
		<b-modal id="survey-settings" :title="$t('VIEWS.Survey.Settings.title')" :ok-title="$t('GENERAL.Forms.save')" :cancel-title="$t('GENERAL.Buttons.close')" v-on:ok="onSaveSettings" content-class="modal-border" size="xl">
			<b-tabs content-class="mt-3" justified>
				<b-tab :title="$t('VIEWS.Survey.Settings.urls')" active>
					<div class="row">
						<div class="col-12 col-md-5">
							<b-input-group :prepend="getSurveyUrl">
								<b-input type="text" v-model="urlInput" />
							</b-input-group>
						</div>
						<div class="col-12 col-md-5">
							<b-input-group>
								<b-select :options="companies" value-field="id" text-field="name" v-model="urlCompany"></b-select>
							</b-input-group>
						</div>
						<div class="col-12 col-md-2">
							<b-input-group>
								<b-button v-on:click.prevent="addUrl" variant="success">{{ $t('GENERAL.Buttons.add') }}</b-button>
							</b-input-group>
						</div>

						<div class="prinor-table col-12" v-if="selectedSurvey != null && selectedSurvey.survey_urls.length > 0">
							<b-table ref="surveysTable" :data="selectedSurvey.survey_urls" :paginated="true" :per-page="15" default-sort="url" :default-sort-direction="'asc'" :sort-icon="'caret-up'">
								<b-table-column field="url" :label="$t('VIEWS.Survey.Settings.urls')" v-slot="props" :sortable="true" :searchable="false" cell-class="text-valign">
									<template>
										<span class="truncate">{{ props.row.url }}</span>
									</template>
								</b-table-column>
								<b-table-column field="company_id" :label="$t('VIEWS.Survey.Settings.associatedCompany')" v-slot="props" :sortable="true" :searchable="false" cell-class="text-valign">
									<template>
										<span class="truncate">{{ getCompanyName(props.row.company_id) }}</span>
									</template>
								</b-table-column>
								<b-table-column field="id" :label="$t('GENERAL.Forms.delete')" v-slot="props" :sortable="true" :searchable="false" cell-class="text-valign">
									<template>
										<a href="" @click.prevent="deleteUrl(props.row.id)" class="btn btn-icon btn-light btn-sm m-1">
											<i class="far fa-trash-alt text-danger"></i>
										</a>
									</template>
								</b-table-column>
							</b-table>
						</div>
					</div>
				</b-tab>
				<b-tab :title="$t('VIEWS.Survey.Settings.Sample.title')">
					<div class="row">
						<div class="col-12 col-md-4">
							<div class="form-group">
								<label>{{ $t('VIEWS.Survey.Settings.Sample.amount') }}</label>
								<b-form-input v-model="sampleData.amount" type="number" min="1" max="100"></b-form-input>
							</div>
						</div>
						<div class="col-12 col-md-2 my-auto">
							<b-button v-on:click.prevent="onProcessSampleData" variant="success">{{ $t('GENERAL.Buttons.execute') }}</b-button>
						</div>
					</div>
				</b-tab>
			</b-tabs>
		</b-modal>
	</div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { PRINOR_TOASTS } from '@/core/services/toast.service';
import ContentLoading from '@/view/component/misc/ContentLoading';
import Swal from 'sweetalert2';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { PRINOR_PROCESSES } from '@/core/services/actions.service';
import {SET_ACTION} from "@/core/services/store/actions.module";
import {mapGetters} from "vuex";
import {PRINOR_PERMISSION} from "@/core/services/permissions.service";
import { v4 as uuidv4 } from 'uuid';

export default {
	name: 'AllSurveys',
	components: { ContentLoading },
	data() {
		return {
			surveys: [],
			themes: [],
			companies: [],
			selectedSurvey: null,
			urlInput: '',
			urlCompany: null,
			sampleData: {
				amount: 1,
			},
		};
	},
	mounted() {
		this.$store.dispatch(SET_ACTION, []);
		this.$store.dispatch(SET_BREADCRUMB, [
			{
				title: this.$t('MENU.Survey.surveys'),
				route: '/survey',
			},
			{ title: this.$t('VIEWS.Survey.All.title') },
		]);
		PRINOR_PROCESSES.isLoading();

		this.onGet();
	},
	computed: {
		...mapGetters(['pageProcesses', 'getSurveyUrl']),
	},
	methods: {
		hasPermission(name) {
			return PRINOR_PERMISSION.action(name);
		},
		checkThemePermission: function(id) {
			let filtered = this.themes.filter(value => { return value.id === id; });
			if (filtered != null && filtered.length === 1) {
				if (filtered[0].default === true || filtered[0].readOnly === true) {
					return true
				}
			}

			return false;
		},
		onProcessSampleData: function() {
			this.processSampleData(this.sampleData);
		},
		processSampleData: function(sampleData) {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.post('survey/sample/' + this.selectedSurvey.id, sampleData).then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						PRINOR_TOASTS.general.confirmation(this, this.$t('VIEWS.Survey.Settings.Sample.successfull'));
						resolve();
					} else {
						PRINOR_TOASTS.general.error(this, data.data.error);
					}
				});
			});
		},
		onSaveTheme: function(themeId, surveyId) {
			this.saveTheme(themeId, surveyId).then(data => {
				if (data.success === true) {
					PRINOR_TOASTS.save.confirmation(this);
				} else {
					PRINOR_TOASTS.save.error(this, data.error);
				}
			});
		},
		saveTheme: function(themeId, surveyId) {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.put('theme/' + surveyId, { theme: themeId })
					.then(({ data }) => {
						resolve(data);
					})
					.catch(() => {
						PRINOR_TOASTS.save.error(this);
						// console.error('Survey All', response);
					});
			});
		},
		onGet: function() {
			this.isLoading = true;
			this.get().then(data => {
				this.surveys = data.data;
			});
			this.getThemes().then(data => {
				this.themes = data.data;
			});
			this.getCompanies().then(data => {
				this.companies = data.data;
			});
		},
		get: function() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.get('survey/all').then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === true) {
							resolve(data.data);
						} else {
							PRINOR_TOASTS.general.error(this, data.data.error);
						}
					}
				});
			});
		},
		getThemes: function() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.get('theme/all').then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === true) {
							resolve(data.data);
						} else {
							PRINOR_TOASTS.general.error(this, data.data.error);
						}
					}
				});
			});
		},
		getCompanies: function() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.get('company/all').then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === true) {
							resolve(data.data);
						} else {
							PRINOR_TOASTS.general.error(this, data.data.error);
						}
					}
				});
			});
		},
		delete: function(id) {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.delete('survey/' + id).then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === true) {
							resolve(data.data);
						} else {
							PRINOR_TOASTS.general.error(this, data.data.error);
						}
					}
				});
			});
		},
		onDelete: function(id, name) {
			Swal.fire({
				title: this.$t('VIEWS.Survey.All.delete'),
				text: this.$t('VIEWS.Survey.All.deleteText', { name: name }),
				icon: 'question',
				confirmButtonText: this.$t('GENERAL.Forms.delete'),
				showCancelButton: true,
				cancelButtonText: this.$t('GENERAL.Buttons.cancel'),
				customClass: {
					confirmButton: 'btn btn-danger',
					cancelButton: 'btn btn-secondary',
				},
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			}).then(result => {
				if (result.isConfirmed) {
					this.delete(id).then(data => {
						if (data.success === true) {
							this.onGet();
						} else {
							PRINOR_TOASTS.delete.error(this);
						}
					});
				}
			});
		},
		showSettings: function(id) {
			let survey = this.surveys.filter(value => { return value.id === id; });
			if (survey != null && survey.length === 1) {
				this.selectedSurvey = survey[0];
			}

			this.$bvModal.show('survey-settings');
		},
		addUrl: function() {
			if (this.urlInput !== '' && this.urlInput != null) {
				this.selectedSurvey.survey_urls.push({id: uuidv4(), 'url': this.urlInput, survey_id: this.selectedSurvey.id, company_id: this.urlCompany });
				this.urlInput = '';
				this.urlCompany = null;
			}
		},
		getCompanyName: function(id) {
			let company = this.companies.filter(value => { return value.id === id; });
			if (company != null && company.length === 1) {
				return company[0].name;
			} else {
				return '';
			}
		},
		onSaveSettings: function() {
			let postData = {
				urls: this.selectedSurvey.survey_urls,
			};

			this.saveSettings(postData).then(data => {
				this.selectedSurvey = data.data;
				for (let i = 0; i < this.surveys.length; i++) {
					if (this.surveys[i].id === this.selectedSurvey.id) {
						this.surveys[i] = this.selectedSurvey;
					}
				}
			});
		},
		saveSettings: function(postData) {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.post('survey/settings/' + this.selectedSurvey.id, postData).then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === true) {
							PRINOR_TOASTS.save.confirmation(this);
							resolve(data.data);
						} else {
							PRINOR_TOASTS.general.error(this, data.data.error);
						}
					}
				});
			});
		},
		deleteUrl: function(id) {
			this.selectedSurvey.survey_urls = this.selectedSurvey.survey_urls.filter(value => { return value.id !== id; });
		},
		showCopy: function(id, name) {
			Swal.fire({
				title: this.$t('VIEWS.Survey.All.copyTitle'),
				text: this.$t('VIEWS.Survey.All.copyText', { name: name }),
				icon: 'question',
				input: 'text',
				inputLabel: this.$t('VIEWS.Survey.Theme.copyInput'),
				inputValue: '',
				confirmButtonText: this.$t('GENERAL.Buttons.copy'),
				showCancelButton: true,
				cancelButtonText: this.$t('GENERAL.Buttons.cancel'),
				customClass: {
					confirmButton: 'btn btn-success',
					cancelButton: 'btn btn-secondary',
				},
				allowOutsideClick: true,
				allowEscapeKey: true,
				allowEnterKey: false,
			}).then(result => {
				if (result.isConfirmed) {
					this.duplicate(id, result.value).then(data => {
						if (data.success === true) {
							PRINOR_TOASTS.general.confirmation(this, this.$t('VIEWS.Survey.All.copySuccessfull'));
							this.onGet();
						} else {
							PRINOR_TOASTS.delete.error(this, data.error);
						}
					});
				}
			});
		},
		duplicate: function(id, value) {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.post('survey/copy', { id: id, name: value }).then(data => {
					if (ApiService.checkSuccess(data)) {
						resolve(data.data);
					}
				});
			});
		},
	},
};
</script>

<style>
.text-valign {
	vertical-align: middle !important;
}
</style>
